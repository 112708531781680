<template>
  <div class="orangelist column align-center mb20">
    <!-- <title-top>商品详情</title-top> -->
    <img src="~images/h5/top-beijing.png" class="w100 position_f" style="height:5.066667rem;" alt />
    <div class="w100 row flex-around font22 f-white mt30 mb32" style="z-index:2;">
      <div :class="{active:currentIndex == 0}" @click="onTab(0)">限时抢购</div>
      <div :class="{active:currentIndex == 1}" @click="onTab(1)">热销榜单</div>
      <div :class="{active:currentIndex == 2}" @click="onTab(2)">好货精选</div>
    </div>
    <div
      class="row jxbj mt20 br8"
      @click="onItem(item)"
      v-for="(item,index) in list"
      :key="index"
      style="z-index:2;"
    >
      <img class="imgsize200 ml16 mt20 mr16 mb20 br8 boder1 bg-999999" :src="item.image_url" alt />
      <div class="flex1 column mt25">
        <div class="font26 f-1A1A1A ellipsis mr10">{{item.product_name}}</div>
        <span class="font24 f-666666 mt20">{{item.store_name}}</span>
        <div class="row align-center mt20">
          <span class="font36 f-E60C0C mr25">￥{{item.price}}</span>
          <img style="width: 2.213333rem;height: .48rem;" src="~images/h5/zhuanxiang.png" alt />
        </div>
        <span class="font24 f-999999" v-if="false">市场价￥288.9</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { promotions } from "network/igou2.js";
import { params } from "@/common/const";
import Vue from "vue";
import { CountDown, NavBar } from "vant";

Vue.use(NavBar);
Vue.use(CountDown);

export default {
  name: "OrangeList",
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      flashSaleProduct: [],
      featuredProduct: [],
      hotProduct: [],
      currentIndex: this.$route.query.currentIndex || 0,
      list: [],
      title: ""
    };
  },
  created() {
    this.getTitle();
  },

  mounted() {
    this._promotions();
  },
  methods: {
    getTitle() {
      let currentIndex = this.currentIndex || 0;
      if (currentIndex == 0) {
        this.title = "限时抢购";
      } else if (currentIndex == 1) {
        this.title = "热销榜单";
      } else if (currentIndex == 2) {
        this.title = "好货精选";
      }
    },
    onTab(index) {
      if (this.currentIndex == index) {
        return;
      }
      this.currentIndex = index;
      this.getTitle();
      this.getList();
    },
    getList() {
      if (this.currentIndex == 0) {
        this.list = this.flashSaleProduct;
      } else if (this.currentIndex == 1) {
        this.list = this.hotProduct;
      } else if (this.currentIndex == 2) {
        this.list = this.featuredProduct;
      }
    },
    onItem(item) {
      let url = params.debug ? params.h5_test : params.h5_pro;
      //url = 'http://192.168.1.5:8080/';
      url += "zydetail?id=" + item.product_id;
      console.log(item);
      let obj = {
        link_type: "promotions",
        url: url,
        name: "",
        tag: "",
        position: 0,
        image_url: ""
      };
      if (this.isAndroid()) {
        window.app.cbMessage(JSON.stringify(obj));
      } else if (this.isiOS()) {
        window.webkit.messageHandlers.cbMessage.postMessage(
          JSON.stringify(obj)
        );
      }
      /*
      this.$router.push({
        path: "/zydetail",
        query: { id: item.product_id }
      });
      */
    },
    replaceImg(list){
      for(let i=0;i<list.length;i++){
        list[i].image_url = list[i].image_url.replace("http://images.igolife.net","");
      }
      return list;
    },
    isAndroid() {
      let u = navigator.userAgent;
      return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    },
    isiOS() {
      let u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    _promotions() {
      promotions().then(res => {
        let list = res.data;
        list = this.replaceImg(list);
        if (list.length > 0) {
          this.flashSaleProduct = list.filter(
            t => t.type === "FlashSaleProduct"
          ); //限时购
          this.featuredProduct = list.filter(t => t.type === "FeaturedProduct"); //精选商品
          this.hotProduct = list.filter(t => t.type === "HotProduct"); //热销商品
          this.getList();
        }
      });
    },
    format(start, end) {
      var t1 = new Date().getTime();
      var t2 = new Date(end).getTime();
      return t2 - t1;
    }
  }
};
</script>
<style>
body {
  background-color: #ebebeb;
}
.active {
  border-bottom: 1px solid #fff;
}
.van-nav-bar {
  background-color: transparent;
  color: #fff;
}
.van-nav-bar__title {
  color: #fff;
}
.flbg {
  background-color: #fce5b8;
  padding: 0.106667rem 0.266667rem 0.106667rem 0.266667rem;
}
.jxbj {
  background-color: #fff;
  width: 9.453333rem;
}
</style>
